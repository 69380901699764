<!--
 * @Author: 钟澳鹏 1269372093@qq.com
 * @Date: 2023-03-21 11:28:18
 * @LastEditors: 钟澳鹏 1269372093@qq.com
 * @LastEditTime: 2023-07-05 15:37:14
 * @FilePath: \dalian\src\components\FooterContent.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
  <div class="footer-content">
    <div>Copyright © Dl-zj.com 长沙银拓信息技术有限公司 All Rights Reserved.</div>
    <div>地址：湖南省长沙市芙蓉区定王台街道芙蓉中路二段98号明城国际中心2009号房</div>
    <div>电话：0731-85648290</div>
  </div>
</template>

<script>
export default {
  name: 'FooterContent'
}
</script>

<style scoped>
.footer-content{
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 62px;
  color: #ffffff;
  font-size: 13px;
  background: #474747;
  gap: 10px;
}
</style>
