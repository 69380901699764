<!--
 * @Author: 钟澳鹏 1269372093@qq.com
 * @Date: 2023-05-17 12:08:30
 * @LastEditors: 钟澳鹏 1269372093@qq.com
 * @LastEditTime: 2023-07-05 14:32:57
 * @FilePath: \dalian\src\components\TabContent2.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
  <div class="tab-content2">
    <img src="../assets/banner4.png" class="banner">
    <div class="content">
      <GridTitle text="企业简介"></GridTitle>
      <div class="grid-1">
        <img src="../assets/tab4/1.png" class="tab4-1">
        <div>长沙银拓信息技术有限公司创立于2014年9月29日，注册资本伍佰万元整，专业从事于信息技术开发、咨询；银行卡的技术开发、咨询及推广；电子设备、计算机软硬件及辅助设备的销售；并成为杉德支付网络服务发展有限公司湖南分公司等公司的收单机构外包服务商。</div>
        <div>长沙银拓公司贯彻以总经理为核心管理；以市场为导向；以银行、支付公司为经营指针；以广大商户为服务对象的发展理念。公司现有工作人员15人，服务商户551户，维护机具数量520台。所服务的支付机构有：杉德支付、海科融通、国通星驿等。</div>
      </div>
    </div>
  </div>
</template>

<script>
import GridTitle from './GridTitle.vue'
export default {
  name: 'TabContent4',
  components:{
    GridTitle
  }
}
</script>

<style scoped>
.banner{
  height: 651px;
  width: 100%;
 }
 .content{
  width: 1200px;
  margin: 72px auto 0 auto;
 }

 .grid-1{
    font-size: 22px;
    font-family: Alibaba PuHuiTi;
    font-weight: 500;
    color: #666666;
    line-height: 32px;
    padding: 67px 0 57px 0;
    text-align: justify;
 }
 .grid-1>div{
    padding: 19px 0;
 }
 .tab4-1{
    height: 555px;
    width: 1015px;
    margin: 0 auto 40px auto;
    display: block;
 }
 .tab4-2{
    height: 343px;
    width: 1258px;
    margin: 103px auto 40px auto;
 }
</style>
