import { render, staticRenderFns } from "./TabContent3.vue?vue&type=template&id=b7d9ad58&scoped=true&"
import script from "./TabContent3.vue?vue&type=script&lang=js&"
export * from "./TabContent3.vue?vue&type=script&lang=js&"
import style0 from "./TabContent3.vue?vue&type=style&index=0&id=b7d9ad58&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "b7d9ad58",
  null
  
)

export default component.exports