<!--
 * @Author: 钟澳鹏 1269372093@qq.com
 * @Date: 2023-05-17 12:08:30
 * @LastEditors: 钟澳鹏 1269372093@qq.com
 * @LastEditTime: 2023-07-05 15:27:15
 * @FilePath: \dalian\src\components\TabContent2.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
  <div class="tab-content2">
    <img src="../assets/banner2.png" class="banner">
    <!-- <div class="content">
      <GridTitle text="我们提供"></GridTitle>
    </div> -->
    <div class="content">
      <div class="product-box">
        <img src="../assets/tab2/1.png">
        <div>
          <div class="title">集成企业级用户登录</div>
          <div class="intro">集成第三方帐号平台，如企业微信、飞书、钉钉、LDAP、Microsoft AD等，实现跨系统对接</div>
          <div class="text-link">免费试用→</div>
        </div>
      </div>
      <div class="product-box">
        <div>
          <div class="title">组织架构同步和部门成员管理</div>
          <div class="intro">一键自动同步第三方帐号平台组织架构和帐号信息，减少繁琐人工录入，降低运维成本，同时支持手动管理部门及成员信息</div>
          <div class="text-link">免费试用→</div>
        </div>
        <img src="../assets/tab2/2.png">
      </div>
      <div class="product-box">
        <img src="../assets/tab2/3.png">
        <div>
          <div class="title">消息推送</div>
          <div class="intro">支持不同类型消息推送至飞书、钉钉、企业微信自建应用中，即时接收工作任务，掌握工作任务变动，提升工作效率</div>
          <div class="text-link">免费试用→</div>
        </div>
      </div>
      <div class="product-box">
        <div>
          <div class="title">单点登录</div>
          <div class="intro">绑定企业微信、飞书、钉钉、LDAP、Microsoft AD等后，再次登录即可通过扫码/跳转方式，便捷安全登录YinTuo</div>
          <div class="text-link">免费试用→</div>
        </div>
        <img src="../assets/tab2/4.png">
      </div>
      <div class="product-box">
        <img src="../assets/tab2/5.png">
        <div>
          <div class="title">安全管理</div>
          <div class="intro">提供IP限制管理、密码策略、两步验证、登录日志、审计日志，细粒度的安全管控，全面确保访问者身份安全，为企业数据提供安全保障</div>
          <div class="text-link">免费试用→</div>
        </div>
      </div>
      <div class="product-box">
        <div>
          <div class="title">数据分析</div>
          <div class="intro">提供企业使用活跃度统计，统计分析不同时间段用户活跃情况，以及不同产品的活跃用户、活跃项目、工作项等 </div>
          <div class="text-link">免费试用→</div>
        </div>
        <img src="../assets/tab2/6.png">
      </div>
    </div>
  </div>
</template>

<script>
// import GridTitle from './GridTitle.vue'
export default {
  name: 'TabContent2',
  components:{
    // GridTitle
  }
}
</script>

<style scoped>
.tab-content2{
  font-size: 0;
}
.banner{
  height: 651px;
  width: 100%;
 }
 .content{
  width: 1200px;
  margin: 0 auto 0 auto;
 }

 .grid-1{
    
 }
 .product-box{
  display: flex;
  align-items: center;
 }
 .title{
  font-size: 42px;
  font-family: Alibaba PuHuiTi;
  font-weight: 400;
  color: #333333;
 }
.intro{
  font-size: 16px;
  font-family: Alibaba PuHuiTi;
  font-weight: 400;
  color: #666666;
  line-height: 24px;
  margin: 30px 0;
}
.text-link{
  font-size: 16px;
  font-family: Alibaba PuHuiTi;
  font-weight: 400;
  color: #4068C7;
}
</style>
