<!--
 * @Author: 钟澳鹏 1269372093@qq.com
 * @Date: 2023-03-21 11:00:28
 * @LastEditors: 钟澳鹏 1269372093@qq.com
 * @LastEditTime: 2023-07-05 16:17:58
 * @FilePath: \dalian\src\components\HeaderContent.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<!--
 * @Author: 钟澳鹏 1269372093@qq.com
 * @Date: 2023-03-21 11:02:09
 * @LastEditors: 钟澳鹏 1269372093@qq.com
 * @LastEditTime: 2023-05-17 10:26:35
 * @FilePath: \dalian\src\index\index.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
    <div>
        <div class="header">
            <img src="../assets/logo.png">
			<div class="tabs">
				<template v-for="(item,index) in tabs">
					<div :class="[active === item.id ? 'tab-active' : '']" :key="index" @click="tabsChange(item)">{{item.name}}</div>
				</template>
			</div>
        </div>
    </div>
</template>

<script>
	export default {
		name: "HeaderContent",
		data() {
			return {
				active: 'TabContent1',
				tabs:[
					{id: 'TabContent1',name:'网站首页'},
					{id: 'TabContent2',name:'商业价值'},
					{id: 'TabContent3',name:'行业资讯'},
					{id: 'TabContent4',name:'企业简介'}
				]
			}
		},
		methods:{
			tabsChange(item){
				this.active = item.id
				this.$emit('change',item.id)
			}
		}
	}
</script>

<style scoped>
.header {
    height: 90px;
    background: white;
	display: flex;
	align-items: center;
	justify-content: center;
}
img{
	height: 37px ;
}
.tabs{
	display: flex;
	justify-content: space-between;
	font-size: 18px;
	color: #666666;
	gap: 94px;
	margin-left: 184px;
}
.tabs>div{
	cursor: pointer;
}
.tab-active{
	color: #4068C7;
	font-weight: bold;
}
</style>